import React from "react";
import { Container, Typography, Divider } from "@mui/material";
import { useNavigate } from 'react-router-dom';

const AboutUs = () => {
  const navigate = useNavigate();

  return (
    <div
      style={{ backgroundColor: "#151E28", minHeight: "100vh", padding: 20 }}
    >
      <nav className="navbar">
        <ul>
          {/* <li onClick={() => scrollToSection(locationRef)}>LOCATION</li> */}
          <li onClick={() => navigate("/")}>HOME</li>
        </ul>
      </nav>
      <Container>
        <Typography
          sx={{ mb: 4, marginTop: 20, fontFamily: "Beckman", color: "white" }}
          variant="h3"
          gutterBottom
          paragraph
          align="center"
        >
          ABOUT US
        </Typography>
        <Divider
          sx={{
            width: "30%",
            backgroundColor: "wheat",
            margin: "0 auto",
            borderBottomWidth: "3px",
          }}
        />
        <div style={{ textAlign: "center", margin: "20px 0" }}>
          <Typography
            variant="h5"
            align="center"
            sx={{
              mb: 4,
              color: "wheat",
              fontFamily: "Beckman",
              fontSize: { xs: "1rem", md: "2rem" },
            }}
          >
            Founded in 2016, Sruhad Investments Limited is a leading real estate
            developer specializing in luxury affordable living. We are dedicated
            to creating exceptional residential spaces that combine elegance
            with accessibility. Our experienced team ensures high-quality,
            innovative solutions that make your dream home a reality. We focus
            on sustainability and community well-being, striving to enhance the
            quality of life for all our residents. At Sruhad Investments
            Limited, we believe everyone deserves a beautiful, affordable home
            where they can truly thrive.
          </Typography>
        </div>
      </Container>
    </div>
  );
};

export default AboutUs;

import React, { useState, useEffect, useRef } from "react";
import {
  Container,
  Typography,
  Box,
  Button,
  Tabs,
  Tab,
  Grid,
  Card,
  CardMedia,
  CardContent,
  Divider,
  Modal,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import CloseIcon from "@mui/icons-material/Close";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import MenuIcon from "@mui/icons-material/Menu";
import CallIcon from "@mui/icons-material/Call";
import { useInView } from "react-intersection-observer";
import "./Overview.css";
import { useNavigate } from "react-router-dom";

import ContactForm from "./ContactForm";
const Overview = () => {
  // const [ref1, inView1] = useInView({ triggerOnce: true, threshold: 0.3 });
  const [ref2, inView2] = useInView({ triggerOnce: true, threshold: 0.3 });
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedTabA, setSelectedTabA] = useState(0);
  const [open, setOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const amenitiesData = [
    { title: "Terrace", icon: "/ameneties/Terrace.png" },
    { title: "Ample Parking", icon: "/ameneties/Parking.png" },
    { title: "Modular Kitchen", icon: "/ameneties/Kitchen.png" },
    { title: "BBQ Area", icon: "/ameneties/bbq.png" },
    { title: "Retail Center", icon: "/ameneties/retail.png" },
    { title: "24x7 Security & CCTV", icon: "/ameneties/security.png" },
    { title: "Outdoor Cabanas", icon: "/ameneties/cabanas.png" },
    { title: "Standby Generator", icon: "/ameneties/generator.png" },
    { title: "Inverter Backup", icon: "/ameneties/Inverter_backup.png" },
    { title: "Refuse Chute", icon: "/ameneties/⁠Refuse_chute.png" },

    { title: "Intercom", icon: "/ameneties/Intercom.png" },
  ];

  const ProjectData = [
    { title: "SEPAL VILLAS", icon: "/03.jpg", soldOut: true },
    { title: "SEPAL HEIGHTS", icon: "/p3.jpg", soldOut: true },
    { title: "SEPAL COURTS", icon: "/p4.png", soldOut: true },
    { title: "STANDARD HOMES", icon: "/p5.png", soldOut: true },
    { title: "SEPAL VIEW", icon: "/p1.png", soldOut: true },
    { title: "SEPAL LIVING", icon: "/p3.jpg", soldOut: false },
  ];
  const unitTypes = [
    {
      name: "Duplex",
      img: "/floorplans/3bhk.png",
      upper: "/floorplans/3bhk_lower.png",
    },
    {
      name: "3 Bed",
      img: "/floorplans/3bhktypea.png",
      typeB: "/floorplans/3bhkb.png",
    },
    { name: "4 Bed", img: "/floorplans/4BHK.png" },
  ];

  // const [selectedTab, setSelectedTab] = useState(0);
  // const [selectedTabA, setSelectedTabA] = useState(0);

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleChangeTabA = (event, newValue) => {
    setSelectedTabA(newValue);
  };

  const handleOpen = (imgSrc) => {
    setCurrentImage(imgSrc);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setCurrentImage(null);
  };

  const amenitiesRef = useRef(null);
  const unitTypesRef = useRef(null);
  const ProjectRef = useRef(null);
  // const locationRef = useRef(null)
  const ContactRef = useRef(null);

  const main = useRef(null);

  // Scroll to the corresponding section
  const scrollToSection = (ref) => {
    ref.current.scrollIntoView({ behavior: "smooth" });
  };

  const renderUnitTypeContent = () => {
    switch (selectedTab) {
      case 0:
        return (
          <>
            <img
              src={unitTypes[0].img}
              alt={unitTypes[0].name}
              style={{ width: "100%", height: "auto", cursor: "pointer" }}
              onClick={() => handleOpen(unitTypes[0].img)}
            />
            <img
              src={unitTypes[0].upper}
              alt={unitTypes[0].name}
              style={{ width: "100%", height: "auto", cursor: "pointer" }}
              onClick={() => handleOpen(unitTypes[0].upper)}
            />
          </>
        );
      case 1:
        return (
          <div style={{ width: "100%" }}>
            <Tabs
              value={selectedTabA}
              onChange={handleChangeTabA}
              sx={tabStyles.vertical}
              orientation="vertical"
              variant="scrollable"
            >
              <Tab label="Type A" />
              <Tab label="Type B" />
            </Tabs>
            <img
              src={selectedTabA === 0 ? unitTypes[1].img : unitTypes[1].typeB}
              alt={`3 Bed Duplex ${selectedTabA === 0 ? "Type A" : "Type B"}`}
              style={{
                width: "100%",
                height: "auto",
                marginTop: 30,
                cursor: "pointer",
              }}
              onClick={() =>
                handleOpen(
                  selectedTabA === 0 ? unitTypes[1].img : unitTypes[1].typeB
                )
              }
            />
          </div>
        );
      case 2:
        return (
          <img
            src={unitTypes[2].img}
            alt={unitTypes[2].name}
            style={{ width: "100%", height: "auto", cursor: "pointer" }}
            onClick={() => handleOpen(unitTypes[2].img)}
          />
        );
      default:
        return null;
    }
  };

  const itemsToShow = amenitiesData.slice(0, -3); // All items except the last three
  const lastThreeItems = amenitiesData.slice(-3); // Last three items
  const tabStyles = {
    vertical: {
      "& .MuiTab-root": {
        color: "white",
        backgroundColor: "transparent",
        "&.Mui-selected": {
          color: "wheat",
          backgroundColor: "rgba(255, 255, 255, 0.1)",
        },
      },
      "& .MuiTabs-indicator": {
        backgroundColor: "transparent",
      },
    },
    horizontal: {
      "& .MuiTab-root": {
        color: "white",
        backgroundColor: "transparent",
        borderBottom: "2px solid white",
        padding: "8px 16px",
        "&.Mui-selected": {
          color: "wheat",
          backgroundColor: "rgba(255, 255, 255, 0.1)",
          borderBottom: "2px solid wheat",
        },
        "&:hover": {
          backgroundColor: "rgba(255, 255, 255, 0.1)",
        },
      },
      "& .MuiTabs-indicator": {
        backgroundColor: "transparent",
      },
      display: "flex",
      justifyContent: "flex-start",
    },
  };

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const drawerContents = (
    <Box
      sx={{
        width: "100vw", // Full screen width
        backgroundColor: "#151E28",
        color: "wheat",
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
      role="presentation"
      onClick={handleDrawerToggle}
      onKeyDown={handleDrawerToggle}
    >
      <IconButton
        edge="end"
        color="inherit"
        aria-label="close"
        onClick={handleDrawerToggle}
        sx={{
          alignSelf: "flex-end",
          color: "wheat",
          marginRight: 20,
          marginTop: 5,
        }}
      >
        <CloseIcon />
      </IconButton>
      <List>
        <ListItem button onClick={() => scrollToSection(amenitiesRef)}>
          <ListItemText
            primary="AMENITIES"
            primaryTypographyProps={{
              sx: {
                fontSize: {
                  xs: "1.5rem", // Extra small screens
                  sm: "1.5rem", // Small screens
                  md: "2.5rem", // Medium screens
                  lg: "2.5rem", // Large screens
                  xl: "2.5rem", // Extra large screens
                },
              },
            }} // Increase font size
            sx={{
              color: "wheat",
              marginLeft: {
                xs: 5, // Extra small screens
                sm: 10, // Small screens
                md: 10, // Medium screens
                lg: 20, // Large screens
                xl: 20, // Extra large screens
              },
              marginTop: {
                xs: 5, // Extra small screens
                sm: 5, // Small screens
                md: 10, // Medium screens
                lg: 10, // Large screens
                xl: 10, // Extra large screens
              },
            }}
          />
        </ListItem>
        <ListItem button onClick={() => scrollToSection(unitTypesRef)}>
          <ListItemText
            primary="UNIT TYPES"
            primaryTypographyProps={{
              sx: {
                fontSize: {
                  xs: "1.5rem", // Extra small screens
                  sm: "1.5rem", // Small screens
                  md: "2.5rem", // Medium screens
                  lg: "2.5rem", // Large screens
                  xl: "2.5rem", // Extra large screens
                },
              },
            }} // Increase font size
            sx={{
              color: "wheat",
              marginLeft: {
                xs: 5, // Extra small screens
                sm: 10, // Small screens
                md: 10, // Medium screens
                lg: 20, // Large screens
                xl: 20, // Extra large screens
              },
              marginTop: {
                xs: 5, // Extra small screens
                sm: 5, // Small screens
                md: 10, // Medium screens
                lg: 10, // Large screens
                xl: 10, // Extra large screens
              },
            }}
          />
        </ListItem>
        <ListItem button onClick={() => scrollToSection(ProjectRef)}>
          <ListItemText
            primary="PROJECTS"
            primaryTypographyProps={{
              sx: {
                fontSize: {
                  xs: "1.5rem", // Extra small screens
                  sm: "1.5rem", // Small screens
                  md: "2.5rem", // Medium screens
                  lg: "2.5rem", // Large screens
                  xl: "2.5rem", // Extra large screens
                },
              },
            }} // Increase font size
            sx={{
              color: "wheat",
              marginLeft: {
                xs: 5, // Extra small screens
                sm: 10, // Small screens
                md: 10, // Medium screens
                lg: 20, // Large screens
                xl: 20, // Extra large screens
              },
              marginTop: {
                xs: 5, // Extra small screens
                sm: 5, // Small screens
                md: 10, // Medium screens
                lg: 10, // Large screens
                xl: 10, // Extra large screens
              },
            }}
          />
        </ListItem>
        <ListItem button onClick={() => navigate("/about-us")}>
          <ListItemText
            primary="ABOUT US"
            primaryTypographyProps={{
              sx: {
                fontSize: {
                  xs: "1.5rem", // Extra small screens
                  sm: "1.5rem", // Small screens
                  md: "2.5rem", // Medium screens
                  lg: "2.5rem", // Large screens
                  xl: "2.5rem", // Extra large screens
                },
              },
            }} // Increase font size
            sx={{
              color: "wheat",
              marginLeft: {
                xs: 5, // Extra small screens
                sm: 10, // Small screens
                md: 10, // Medium screens
                lg: 20, // Large screens
                xl: 20, // Extra large screens
              },
              marginTop: {
                xs: 5, // Extra small screens
                sm: 5, // Small screens
                md: 10, // Medium screens
                lg: 10, // Large screens
                xl: 10, // Extra large screens
              },
            }}
          />
        </ListItem>
      </List>
    </Box>
  );

  return (
    <>
      {isMobile ? (
        <nav
          className="navbar"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "0 16px",
            backgroundColor: "#151E28",
          }}
        >
          <div
            className="navbar-left"
            style={{ display: "flex", alignItems: "center" }}
          >
            <div className="menu-icon">
              <IconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={handleDrawerToggle}
                sx={{ display: { xs: "block", md: "none" }, color: "wheat" }}
              >
                <MenuIcon />
              </IconButton>
              <Drawer
                anchor="left"
                open={drawerOpen}
                onClose={handleDrawerToggle}
                sx={{
                  "& .MuiDrawer-paper": {
                    backgroundColor: "transparent", // Make the background transparent
                    color: "black",
                    width: "100vw", // Full screen width
                  },
                }}
              >
                {drawerContents}
              </Drawer>
            </div>
          </div>
          <div
            className="navbar-center"
            style={{ flex: 1, textAlign: "center" }}
          >
            <div className="logo">
              <img
                src="/S2.png"
                alt="Logo"
                style={{
                  height: 50,
                  width: "auto",
                  marginTop: 10,
                  marginRight: 75,
                }}
                onClick={() => scrollToSection(main)}
              />
            </div>
          </div>
          {/* <div style={{ display: 'flex', alignItems: 'center', width: 'auto' }}>
          <a
            href="tel:+919819073142"
            style={{
              color: "inherit",
              textDecoration: "none",
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <CallIcon style={{ color: "white", fontSize: 10 }} />
            <Typography
              variant="h4"
              className="overview-text"
              sx={{
                ml: 1,
                color: "wheat",
                fontSize: {
                  xs: "0.5rem",
                  sm: "1.7rem",
                  md: "2rem",
                },
                fontFamily: "Beckman",
              }}
            >
              256705332050
            </Typography>
          </a>
        </div> */}
        </nav>
      ) : (
        // <>
        //   <div style={{ backgroundColor: "wheat" }}>
        //     <IconButton
        //       edge="start"
        //       color="inherit"
        //       aria-label="menu"
        //       onClick={handleDrawerToggle}
        //       sx={{ position: "fixed", top: 10, left: 10, zIndex: 2 }}
        //     >
        //       <MenuIcon />
        //     </IconButton>
        //   </div>
        //   <Drawer
        //     anchor="left"
        //     open={drawerOpen}
        //     onClose={handleDrawerToggle}
        //     sx={{
        //       "& .MuiDrawer-paper": {
        //         backgroundColor: "black",
        //         color: "black",
        //         width: 250,
        //       },
        //     }}
        //   >
        //     {drawerContents}
        //   </Drawer>
        // </>
        <nav className="navbar">
          <div class="navbar-left">
            <div class="menu-icon">
              <>
                <div style={{ backgroundColor: "wheat" }}>
                  <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    onClick={handleDrawerToggle}
                    sx={{ position: "fixed", top: 20, left: 100, zIndex: 2 }}
                  >
                    <MenuIcon />
                  </IconButton>
                </div>
                <Drawer
                  anchor="left"
                  open={drawerOpen}
                  onClose={handleDrawerToggle}
                  sx={{
                    "& .MuiDrawer-paper": {
                      backgroundColor: "transparent", // Make the background transparent

                      color: "black",
                      width: "100vw", // Full screen width
                    },
                  }}
                >
                  {drawerContents}
                </Drawer>
              </>
            </div>
          </div>
          <div class="navbar-center">
            <div class="logo">
              <img
                src="/S2.png"
                alt="Logo"
                style={{
                  height: 50,
                  width: "auto",
                  marginTop: 10,
                  marginLeft: 200,
                }}
                onClick={() => scrollToSection(main)}
              />
            </div>
          </div>
          <div style={{ width: 250 }}>
            <a
              href="tel:+919819073142"
              style={{
                color: "inherit",
                textDecoration: "none",
                display: "flex",
                alignItems: "left",
              }}
            >
              <CallIcon style={{ color: "white", fontSize: 30 }}></CallIcon>
              <Typography
                variant="h4"
                className="overview-text"
                sx={{
                  ml: 1,
                  color: "wheat",
                  fontSize: {
                    xs: "1.5rem",
                    md: "1.5rem",
                    fontFamily: "Beckman",
                  },
                }}
              >
                + 256705332050
              </Typography>
            </a>
          </div>
        </nav>
      )}

      <div className="overview-container">
        {/* <Box
          className="logo-container"
          sx={{
            position: "absolute",
            top: 0,
            left: "50%",
            transform: "translateX(-50%)",
            zIndex: 1,
            display: "flex",
            justifyContent: "center",
            width: "100%",
            padding: "16px 0",
          }}
        >
          <img
            src="/S2.png"
            alt="Logo"
            style={{ height: 100, width: "auto", marginTop: 30 }}
          />
        </Box> */}

        <section ref={main}>
          {isMobile ? (
            <Box
              display="flex"
              flexDirection={{ xs: "column", md: "row" }}
              sx={{ padding: { md: "24px" } }} // Adjust marginTop for mobile view
            >
              <Box display={{ xs: "flex", md: "none" }} mt={{ xs: 8, md: 0 }}>
                <img
                  src="/sepal_banner2.png"
                  alt="Your Description"
                  style={{ width: "100%", height: "auto" }}
                />
              </Box>
              <Container>
                <Box mt={{ xs: 0, md: 15 }} ml={{ xs: 2, md: 5 }}>
                  <Typography
                    variant="h3"
                    className="overview-text slide-in-right"
                    sx={{
                      fontSize: {
                        xs: "4rem",
                        md: "4rem",
                        xl: "8rem",
                        fontFamily: "Beckman", // Apply Beckman font
                      },
                    }} // Increased font size
                  >
                    SEPAL
                  </Typography>
                  <Typography
                    variant="h3"
                    className="overview-text slide-in-right"
                    sx={{
                      fontSize: {
                        xs: "4rem",
                        md: "4rem",
                        xl: "8rem",
                        fontFamily: "Beckman", // Apply Beckman font
                      },
                    }} // Increased font size
                  >
                    GARDEN
                  </Typography>
                  <Typography
                    variant="h4"
                    className="overview-text"
                    sx={{
                      ml: 1,
                      color: "wheat",
                      fontSize: {
                        xs: "0.7rem",
                        md: "1rem",
                        fontFamily: "Beckman", // Apply Beckman font
                        fontStyle: "italic", // Add this line to make the font italic
                      },
                    }}
                  >
                    Project By Sruhad Investments
                  </Typography>
                  <Box mt={{ xs: 4, md: 8 }}>
                    <Box display="flex" alignItems="center">
                      <LocationOnIcon
                        style={{ color: "wheat", fontSize: "2rem" }}
                      />
                      <Typography
                        variant="h4"
                        className="overview-text"
                        sx={{
                          ml: 1,
                          color: "wheat",
                          fontSize: {
                            xs: "1rem",
                            md: "1rem",
                            fontFamily: "Beckman",
                          },
                        }}
                      >
                        Bugolobi, Kampala
                      </Typography>
                    </Box>
                  </Box>
                  <Box mt={4}>
                    <Button
                      variant="outlined"
                      sx={{
                        fontFamily: "Beckman",
                        borderColor: "white",
                        color: "white",
                        px: 4,
                        py: 2,
                        fontSize: { xs: "1rem", md: "1.25rem" },
                        width: { xs: "100%", md: 300 },
                        mt: { xs: 4, md: 8 },
                      }}
                      onClick={() => scrollToSection(ContactRef)}
                    >
                      Book Now
                    </Button>
                  </Box>
                </Box>
              </Container>
            </Box>
          ) : null}
          {!isMobile ? (
            <Box
              className="cover-image-container"
              display="flex"
              flexDirection={{ xs: "column", md: "row" }}
              alignItems="center"
              justifyContent="center"
              px={2}
              sx={{ padding: { xs: "16px", md: "24px" } }} // Adjust padding
              position="relative" // To enable absolute positioning of text
            >
              {/* Left side text content */}

              <Box
                sx={{
                  position: "relative",
                  width: { xs: "100%", md: "100%" }, // Image takes 70% width on larger screens
                  height: "auto",
                }}
              >
                <img
                  src="/Sepal_banner1.png"
                  alt="Your Description"
                  style={{
                    width: "100%",
                    height: "100%", // Ensure the image fills the height of the container
                    objectFit: "cover", // This makes the image behave like a background cover
                  }}
                />

                {/* Text overlay on the image */}
                <Box
                  sx={{
                    position: "absolute",
                    top: "25%",
                    left: "17%",
                    transform: "translate(-50%, -50%)", // Center the text on the image
                    color: "white", // Text color
                    textAlign: "left", // Center text
                    zIndex: 3, // Make sure text appears above the image
                  }}
                >
                  <Typography
                    variant="h3"
                    className="overview-text slide-in-right"
                    sx={{
                      fontSize: {
                        xs: "4rem",
                        md: "4rem",
                        xl: "8rem",
                        fontFamily: "Beckman",
                      },
                    }}
                  >
                    SEPAL
                  </Typography>
                  <Typography
                    variant="h3"
                    className="overview-text slide-in-right"
                    sx={{
                      fontSize: {
                        xs: "4rem",
                        md: "4rem",
                        xl: "8rem",
                        fontFamily: "Beckman",
                      },
                    }}
                  >
                    GARDEN
                  </Typography>
                  <Typography
                    variant="h4"
                    className="overview-text"
                    sx={{
                      ml: 1,
                      color: "wheat",
                      fontSize: {
                        xs: "1.5rem",
                        md: "1rem",
                        fontFamily: "Beckman",
                        fontStyle: "italic",
                      },
                    }}
                  >
                    Project By Sruhad Investments
                  </Typography>
                  <Box mt={{ xs: 4, md: 8 }}>
                    <Box display="flex" alignItems="center">
                      <LocationOnIcon
                        style={{ color: "wheat", fontSize: "2rem" }}
                      />
                      <Typography
                        variant="h4"
                        className="overview-text"
                        sx={{
                          ml: 1,
                          color: "wheat",
                          fontSize: {
                            xs: "1.5rem",
                            md: "1rem",
                            fontFamily: "Beckman",
                          },
                        }}
                      >
                        Bugolobi, Kampala
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    mt={4}
                    display="flex"
                    justifyContent={{ xs: "center", md: "left" }}
                  >
                    <Button
                      variant="outlined"
                      sx={{
                        fontFamily: "Beckman",
                        borderColor: "white",
                        color: "white",
                        px: 4,
                        py: 2,
                        fontSize: { xs: "1rem", md: "1.25rem" },
                        width: { xs: "100%", md: 300 },
                        mt: { xs: 4, md: 8 },
                      }}
                      onClick={() => scrollToSection(ContactRef)}
                    >
                      Book Now
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          ) : null}
        </section>
        {/* <div style={{ marginTop: 20 }}>
          <Divider
            sx={{
              width: "90%",
              backgroundColor: "wheat",
              margin: "0 auto",
              marginTop: 10,
              borderBottomWidth: "1px",
            }}
          />
        </div> */}

        {/* Sepal Garden */}
        <Container>
          <Box display="flex" justifyContent="center" alignItems="center">
            <img
              src="/building.png"
              alt="Logo"
              style={{ height: 100, marginTop: 20 }}
            />
          </Box>

          <Typography
            variant="h3"
            align="center"
            sx={{ mb: 4, color: "white", marginTop: 3, fontFamily: "Beckman" }}
          >
            SEPAL GARDEN
          </Typography>
          <Divider
            sx={{
              width: "30%",
              backgroundColor: "wheat",
              margin: "0 auto",
              borderBottomWidth: "3px",
            }}
          />
          <Typography
            // variant="h5"
            align="center"
            sx={{
              width: "90%",
              mb: 4,
              color: "wheat",
              marginTop: 5,
              marginLeft: 2.5,
              fontSize: { xs: "0.8rem", md: "2rem", fontFamily: "Beckman" },
              textTransform: "none", // Ensure text is not transformed to uppercase
            }}
          >
            Our latest project Sepal Garden features 38 exquisite residential
            units designed for luxury affordable living. Nestled in a serene
            location, Sepal Garden offers stylish, modern homes with top-notch
            amenities and thoughtful design. Each unit is crafted to provide
            comfort and elegance while maintaining affordability. Enjoy spacious
            spaces, various facilities, and a vibrant community, all at your
            doorstep. Experience the perfect blend of sophistication and value
            at Sepal Garden, where your ideal home awaits. Discover a vibrant
            community and a new standard of living with us.
          </Typography>
        </Container>

        {/* Amenities Section */}
        <section ref={amenitiesRef}>
          <Container className="amenities-section">
            <Typography
              sx={{
                mb: 4,
                color: "white",
                marginTop: 5,
                fontFamily: "Beckman",
              }}
              variant="h3"
              gutterBottom
              paragraph
              align="center"
              color={"white"}
            >
              AMENITIES
            </Typography>
            <Divider
              sx={{
                width: "20%",
                backgroundColor: "wheat",
                margin: "0 auto",
                borderBottomWidth: "3px",
              }}
            />
            <Grid container spacing={2} sx={{ marginTop: 5 }}>
              {/* Regular items */}
              {itemsToShow.map((amenity, index) => (
                <Grid item xs={12} sm={6} md={3} key={index}>
                  <Card
                    className="amenity-card"
                    sx={{
                      backgroundColor: "#151E28",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      p: 2,
                      maxWidth: 200,
                    }}
                  >
                    <CardMedia
                      sx={{
                        width: 90,
                        height: 70,
                        backgroundColor: "#151E28",
                        mb: 1,
                      }}
                      component="img"
                      image={amenity.icon}
                      alt={amenity.title}
                    />
                    <CardContent sx={{ textAlign: "center" }}>
                      <Typography
                        sx={{ fontFamily: "Beckman" }}
                        variant="body1"
                        color="wheat"
                      >
                        {amenity.title}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              ))}

              {/* Container for last three items */}
              <Grid
                container
                spacing={2}
                justifyContent="center"
                sx={{ marginTop: 2 }}
              >
                {lastThreeItems.map((amenity, index) => (
                  <Grid item xs={12} sm={6} md={3} key={index}>
                    <Card
                      className="amenity-card"
                      sx={{
                        backgroundColor: "#151E28",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        p: 2,
                        maxWidth: 200,
                      }}
                    >
                      <CardMedia
                        sx={{
                          width: 90,
                          height: 70,
                          backgroundColor: "#151E28",
                          mb: 1,
                        }}
                        component="img"
                        image={amenity.icon}
                        alt={amenity.title}
                      />
                      <CardContent sx={{ textAlign: "center" }}>
                        <Typography
                          sx={{ fontFamily: "Beckman" }}
                          variant="body1"
                          color="wheat"
                        >
                          {amenity.title}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Container>
        </section>

        {/* FlooorPlans Section */}
        <section ref={unitTypesRef}>
          <Container className="amenities-section">
            <Box
              ref={ref2}
              className={`fade-in-section ${inView2 ? "is-visible" : ""}`}
              px={{ xs: 2, md: 20 }}
            >
              <Box textAlign="center" marginTop={10}>
                <Typography
                  sx={{ fontFamily: "Beckman" }}
                  variant="h3"
                  gutterBottom
                  paragraph
                  color={"white"}
                >
                  UNIT TYPES
                </Typography>
                <Divider
                  sx={{
                    width: "20%",
                    backgroundColor: "wheat",
                    margin: "0 auto",
                    borderBottomWidth: "3px",
                  }}
                />
                <Box mt={10} display="flex" justifyContent="center">
                  <Tabs
                    value={selectedTab}
                    onChange={handleChange}
                    sx={tabStyles.horizontal}
                    variant="scrollable"
                    scrollButtons="auto"
                  >
                    <Tab label="Duplex" />
                    <Tab label="3 BHK" />
                    <Tab label="4 BHK" />
                  </Tabs>
                </Box>
                <Box
                  mt={4}
                  display="flex"
                  justifyContent="center"
                  flexDirection="column"
                  alignItems="center"
                >
                  {renderUnitTypeContent()}
                </Box>
              </Box>
            </Box>
          </Container>

          <Modal
            open={open}
            onClose={handleClose}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box sx={{ position: "relative", width: "90%", maxWidth: 1000 }}>
              <IconButton
                onClick={handleClose}
                sx={{
                  position: "absolute",
                  top: 10,
                  right: 10,
                  color: "white",
                  zIndex: 2,
                }}
              >
                <CloseIcon />
              </IconButton>
              <img
                src={currentImage}
                alt="Full Screen"
                style={{ width: "100%", height: "auto" }}
              />
            </Box>
          </Modal>
        </section>

        {/* Project Section */}

        <div style={{ marginTop: 20 }}>
          <Divider
            sx={{
              width: "90%",
              backgroundColor: "wheat",
              margin: "0 auto",
              marginTop: 10,
              borderBottomWidth: "1px",
            }}
          />
        </div>
        <section ref={ProjectRef}>
          <Container className="amenities-section">
            <Typography
              sx={{
                mb: 4,
                color: "white",
                marginTop: 10,
                fontFamily: "Beckman",
              }}
              variant="h3"
              gutterBottom
              paragraph
              align="center"
              color={"white"}
            >
              PROJECTS
            </Typography>
            <Divider
              sx={{
                width: "20%",
                backgroundColor: "wheat",
                margin: "0 auto",
                borderBottomWidth: "3px",
              }}
            />{" "}
            ,
            <Grid
              container
              spacing={2}
              sx={{
                marginTop: 5,
                alignContent: "center",
                alignItems: "center",
              }}
            >
              {ProjectData.map((amenity, index) => (
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <Card
                    className="amenity-card"
                    sx={{
                      backgroundColor: "#151E28",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      p: 2,
                      maxWidth: 900,
                      boxShadow: `0 4px 20px rgba(245, 222, 179, 1)`,
                      borderRadius: 2,
                      position: "relative", // Required for label positioning
                    }}
                  >
                    <CardMedia
                      sx={{
                        width: "100%",
                        height: 400,
                        backgroundColor: "#151E28",
                        mb: 1,
                        objectFit: "cover",
                      }}
                      component="img"
                      image={amenity.icon}
                      alt={amenity.title}
                    />

                    {/* "Sold Out" Label */}
                    {amenity.soldOut ? (
                      <Box
                        sx={{
                          position: "absolute",
                          top: 10,
                          right: 10,
                          backgroundColor: "red",
                          color: "white",
                          px: 2,
                          py: 1,
                          borderRadius: 1,
                          fontFamily: "Beckman",
                          fontWeight: "bold",
                        }}
                      >
                        Sold Out
                      </Box>
                    ) : (
                      <Box
                        sx={{
                          position: "absolute",
                          top: 10,
                          right: 10,
                          backgroundColor: "green",
                          color: "white",
                          px: 2,
                          py: 1,
                          borderRadius: 1,
                          fontFamily: "Beckman",
                          fontWeight: "bold",
                        }}
                      >
                        UpComing
                      </Box>
                    )}

                    <CardContent sx={{ textAlign: "center" }}>
                      <Typography
                        sx={{ fontFamily: "Beckman" }}
                        variant="body1"
                        color="wheat"
                      >
                        {amenity.title}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Container>
        </section>

        {/* Location Section */}

        <div style={{ marginTop: 20 }}>
          <Divider
            sx={{
              width: "90%",
              backgroundColor: "wheat",
              margin: "0 auto",
              marginTop: 10,
              borderBottomWidth: "1px",
            }}
          />
        </div>
        <Container className="amenities-section">
          {/* <Typography
            variant="h4"
            align="center"
            gutterBottom
            color={"whitesmoke"}
            style={{ marginTop: 50 }}
            sx={{ fontFamily: "Beckman" }}
          >
            LOCATION
          </Typography> */}
          <Typography
            sx={{ mb: 4, color: "white", marginTop: 10, fontFamily: "Beckman" }}
            variant="h3"
            gutterBottom
            paragraph
            align="center"
            color={"white"}
          >
            LOCATION
          </Typography>
          <Divider
            sx={{
              width: "30%",
              backgroundColor: "wheat",
              margin: "0 auto",
              marginTop: 0.1,
              borderBottomWidth: "3px",
            }}
          />

          <div style={{ textAlign: "center", marginLeft: 20, marginRight: 20 }}>
            <img
              src="/darkmap.png"
              alt="Logo"
              style={{ width: "100%", height: "auto", marginTop: 30 }}
              onClick={() =>
                window.open(
                  "https://www.google.com/maps/place/Sepal+Garden/@0.322158,32.6166885,15z/data=!4m6!3m5!1s0x177dbb000c90500f:0x2c98a6daaf38887c!8m2!3d0.322158!4d32.6166885!16s%2Fg%2F11w7md413t?entry=ttu"
                )
              }
            />
            {/* Adjusted margins for responsiveness */}
          </div>
        </Container>
        {/* ABout Us Section */}

        <div style={{ marginTop: 20 }}>
          <Divider
            sx={{
              width: "90%",
              backgroundColor: "wheat",
              margin: "0 auto",
              marginTop: 10,
              borderBottomWidth: "1px",
            }}
          />
        </div>
        <section ref={ContactRef}>
          <Container className="amenities-section">
            {/* <Typography
            variant="h4"
            align="center"
            gutterBottom
            color={"whitesmoke"}
            style={{ marginTop: 50 }}
            sx={{ fontFamily: "Beckman" }}
          >
            ABOUT US
          </Typography> */}
            <Typography
              sx={{
                mb: 4,
                color: "white",
                marginTop: 10,
                fontFamily: "Beckman",
              }}
              variant="h3"
              gutterBottom
              paragraph
              align="center"
              color={"white"}
            >
              CONTACT US
            </Typography>
            <Divider
              sx={{
                width: "30%",
                backgroundColor: "wheat",
                margin: "0 auto",
                borderBottomWidth: "3px",
              }}
            />
            <ContactForm />

            <Box display="flex" justifyContent="center" alignItems="center">
              <img
                src="/S1.png"
                alt="Logo"
                style={{ height: 200, marginTop: 20 }}
              />
            </Box>

            <Box
              display="flex"
              justifyContent="center"
              flexDirection="row"
              alignItems="center"
              mt={10}
            >
              <IconButton
                onClick={() =>
                  window.open(
                    "https://www.instagram.com/sruhad.inv?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=="
                  )
                }
              >
                <InstagramIcon style={{ fontSize: 40, color: "wheat" }} />
              </IconButton>
              <IconButton
                onClick={() =>
                  window.open("https://www.facebook.com/sruhad.inv/")
                }
              >
                <FacebookIcon style={{ fontSize: 40, color: "wheat" }} />
              </IconButton>
              <IconButton
                sx={{ color: "green", fontSize: 100 }}
                onClick={() =>
                  window.open(`https://wa.me/${"+256705332050"}`, "_blank")
                }
              >
                <WhatsAppIcon style={{ fontSize: 40, color: "wheat" }} />
              </IconButton>
            </Box>

            {/* Adjusted margins for responsiveness */}

            {/* <div style={{ textAlign: "center", marginLeft: 20, marginRight: 20 }}>
            <Typography
              variant="h5"
              align="center"
              sx={{
                mb: 4,
                color: "wheat",
                marginTop: 5,
                fontFamily: "Beckman",
                fontSize: { xs: "1rem", md: "2rem" },
              }}
            >
              Founded in 2016, Sruhad Investments Limited is a leading real
              estate developer specializing in luxury affordable living. We are
              dedicated to creating exceptional residential spaces that combine
              elegance with accessibility. Our experienced team ensures
              high-quality, innovative solutions that make your dream home a
              reality. We focus on sustainability and community well-being,
              striving to enhance the quality of life for all our residents. At
              Sruhad Investments Limited, we believe everyone deserves a
              beautiful, affordable home where they can truly thrive.
            </Typography>
          </div> */}
          </Container>
        </section>
        <div style={{ marginTop: 20 }}>
          <Divider
            sx={{
              width: "90%",
              backgroundColor: "wheat",
              margin: "0 auto",
              marginTop: 10,
              borderBottomWidth: "1px",
            }}
          />
        </div>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          style={{ marginTop: 50 }}
        >
          <Typography
            variant="h6"
            sx={{
              ml: 1,
              color: "grey",
              fontFamily: "Beckman",
              fontSize: { xs: "0.7rem", md: "1rem" },
            }}
          >
            © 2024 Kenayo Consultancy Services Private Limited. All rights
            reserved.
          </Typography>
        </Box>
      </div>
    </>
  );
};

export default Overview;

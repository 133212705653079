// src/components/FloatingButton.js
import React from "react";
// import Fab from '@mui/material/Fab';
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import Box from "@mui/material/Box";
import { IconButton } from "@mui/material";

const FloatingButton = () => {
  const whatsappNumber = "+256705332050"; // Replace with your WhatsApp number

  return (
    <Box
      position="fixed"
      bottom={16} // Adjust as needed
      left={1} // Adjust as needed
      display="flex"
      flexDirection="column"
      alignItems="center"
      gap={1} // Space between icons
    >
      <IconButton
        color="primary"
        aria-label="whatsapp"
        onClick={() => window.open(`https://wa.me/${whatsappNumber}`, "_blank")}
      >
        <WhatsAppIcon style={{ fontSize: 20, color: "wheat" }} />
      </IconButton>
      <IconButton
        color="primary"
        aria-label="instagram"
        onClick={() =>
          window.open("https://www.instagram.com/sruhad.inv", "_blank")
        }
      >
        <InstagramIcon style={{ fontSize: 20, color: "wheat" }} />
      </IconButton>
      <IconButton
        color="primary"
        aria-label="facebook"
        onClick={() =>
          window.open("https://www.facebook.com/sruhad.inv", "_blank")
        }
      >
        <FacebookIcon style={{ fontSize: 20, color: "wheat" }} />
      </IconButton>
    </Box>
  );
};

export default FloatingButton;

// src/App.js
import React from "react";
import FloatingButton from "./pages/FloatingButton";
import Overview from "./pages/Overview";
import AboutUs from "./pages/AboutUs"; // Import the new AboutUs component

import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

function App() {
  return (
    <div className="App-header">
      <Router>
        <Routes>
          <Route path="/" element={<Overview />} />
          <Route path="/about-us" element={<AboutUs />} /> {/* Add the route */}
        </Routes>
      </Router>

      <FloatingButton />
    </div>
  );
}

export default App;

import { useState } from 'react';
import { useForm } from 'react-hook-form';
import emailjs from '@emailjs/browser';
import { TextField, Button, Snackbar, Alert, Container, Grid, styled } from '@mui/material';

// Styled container with custom background color
const FormContainer = styled(Container)(({ theme }) => ({
  marginTop: theme.spacing(4),
  backgroundColor: '#151E28', // Set background color
  padding: theme.spacing(4), // Add some padding
  borderRadius: theme.shape.borderRadius, // Optional: add border radius
  boxShadow: theme.shadows[3], // Optional: add shadow for better visual
}));

// Styled text field with custom text color
const CustomTextField = styled(TextField)(({ theme }) => ({
  color: 'wheat',
  '& .MuiInputBase-root': {
    color: 'wheat',
  },
  '& .MuiFormLabel-root': {
    color: 'wheat', // Set label color to wheat
  },

  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'wheat',
    },
    '&:hover fieldset': {
      borderColor: 'wheat',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'wheat',
    },
  },
}));

// Styled submit button with custom text and background color
const SubmitButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
  color: 'wheat', // Set text color to wheat
  backgroundColor: '#151E28', // Set background color
  '&:hover': {
    backgroundColor: '#151E28', // Darker shade for hover effect
  },
}));

const ContactForm = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const [disabled, setDisabled] = useState(false);
  const [alertInfo, setAlertInfo] = useState({
    open: false,
    message: '',
    type: '',
  });

  const toggleAlert = (message, type) => {
    setAlertInfo({ open: true, message, type });
    setTimeout(() => {
      setAlertInfo({ open: false, message: '', type: '' });
    }, 5000);
  };

  const onSubmit = async (data) => {
    const { name, email, subject, message } = data;
    try {
      setDisabled(true);

      const templateParams = {
        name,
        email,
        subject,
        message,
      };

      await emailjs.send(
        import.meta.env.VITE_SERVICE_ID,
        import.meta.env.VITE_TEMPLATE_ID,
        templateParams,
        import.meta.env.VITE_PUBLIC_KEY,
      );

      toggleAlert('Form submission was successful!', 'success');
    } catch (e) {
      console.error(e);
      toggleAlert('Uh oh. Something went wrong.', 'error');
    } finally {
      setDisabled(false);
      reset();
    }
  };

  return (
    <FormContainer>
      <form id='contact-form' onSubmit={handleSubmit(onSubmit)} noValidate>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <CustomTextField
             
              label='Name'
              variant='outlined'
              fullWidth
              {...register('name', {
                required: 'Please enter your name',
                maxLength: {
                  value: 30,
                  message: 'Please use 30 characters or less',
                },
              })}
              error={!!errors.name}
              helperText={errors.name?.message}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomTextField
              label='Email address'
              variant='outlined'
              fullWidth
              {...register('email', {
                required: 'Please enter your email',
                pattern: {
                  value: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                  message: 'Please enter a valid email address',
                },
              })}
              error={!!errors.email}
              helperText={errors.email?.message}
            />
          </Grid>
          <Grid item xs={12}>
            <CustomTextField
              label='Subject'
              variant='outlined'
              fullWidth
              {...register('subject', {
                required: 'Please enter a subject',
                maxLength: {
                  value: 75,
                  message: 'Subject cannot exceed 75 characters',
                },
              })}
              error={!!errors.subject}
              helperText={errors.subject?.message}
            />
          </Grid>
          <Grid item xs={12}>
            <CustomTextField
              label='Message'
              variant='outlined'
              fullWidth
              multiline
              rows={3}
              {...register('message', {
                required: 'Please enter a message',
              })}
              error={!!errors.message}
              helperText={errors.message?.message}
            />
          </Grid>
          <Grid item xs={12}>
            <SubmitButton
              type='submit'
              variant='contained'
              color='primary'
              disabled={disabled}
            >
              Submit
            </SubmitButton>
          </Grid>
        </Grid>
      </form>
      <Snackbar
        open={alertInfo.open}
        autoHideDuration={5000}
        onClose={() => setAlertInfo({ ...alertInfo, open: false })}
      >
        <Alert
          onClose={() => setAlertInfo({ ...alertInfo, open: false })}
          severity={alertInfo.type}
          sx={{ width: '100%' }}
        >
          {alertInfo.message}
        </Alert>
      </Snackbar>
    </FormContainer>
  );
};

export default ContactForm;
